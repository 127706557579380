const navExpand = [].slice.call(document.querySelectorAll(".nav-expand"));
const backLink = `<li class="nav-item"><a class="nav-link nav-back-link" href="javascript:;">Zurück</a></li>`;

navExpand.forEach(item => {
  item
    .querySelector(".nav-expand-content")
    .insertAdjacentHTML("afterbegin", backLink);
  item
    .querySelector(".nav-link")
    .addEventListener("click", () => item.classList.add("active"));
  item
    .querySelector(".nav-back-link")
    .addEventListener("click", () => item.classList.remove("active"));
});

// ---------------------------------------
// not-so-important stuff starts here

const ham = document.querySelector(".nav-burger");
ham.addEventListener("click", function() {
  document.body.classList.toggle("nav-is-toggled");
  if (!document.body.classList.contains("nav-is-toggle")) {
    document.querySelectorAll(".nav-expand").forEach(nav => {
      nav.classList.remove("active");
    });
  }
});
