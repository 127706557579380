const openModal = el => { 
    document.getElementById(el).classList.add('active')
}
const closeModal = el => { 
    document.getElementById(el).classList.remove('active')
    sessionStorage.setItem('amon_modal', 'hide')
}
window.addEventListener('load', () => { 
    if (document.getElementById('modal-corona') && sessionStorage.getItem('amon_modal') !== 'hide') { 
        openModal('modal-corona')
    }
})

const removeAmonModal = (el) => {
	document.body.removeChild(el.parentNode.parentNode);
	document.body.style.height = ""
	document.body.style.overflow = "auto"
	sessionStorage.setItem('amon_modal_job', 'hide')
 }

const createAmonModal = (content) => {
	const modal = document.createElement('div')
	const modalContent = document.createElement('div')
	const close = document.createElement('div')

	modal.id = 'modal'
	modalContent.classList.add('modal__content')
	close.classList.add('close')

	close.innerText = "×"
	close.addEventListener('click', () => {
		removeAmonModal(modal)
	})

	modalContent.appendChild(close)
	modalContent.appendChild(content)
	modal.appendChild(modalContent)

	document.body.appendChild( modal )
	return modal
}

window.addEventListener('keyup', e => { 
    e.key === "Escape" && closeModal('modal-corona')
})

function setCookie(cName, cValue, expDays) {
	let date = new Date();
	date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

const closeNewsModal = (el) => {
	setCookie('amon_news_modal', 'hide', 30)
	document.querySelector('.news__modal').classList.add('hidden')
	if (el.dataset.href) {
		window.location.href = el.dataset.href
	}
}

const showAmonModal = (mod) => {
	document.body.append(mod.content.cloneNode(true))
	document.body.style.height = "100vh"
	document.body.style.overflow = "hidden"
 }
// Modal Stellenanzeige

if (!document.querySelector('.news__modal') && !sessionStorage.getItem('amon_modal_job')) {
	if (document.getElementById('amon_modal')) {
		showAmonModal(amon_modal)	
	}
}
class AmonModal {
	constructor() { 

	}

	showModalFromTemplate(tmpid) {
		document.body.append(tmpid.content.cloneNode(true))
		document.body.style.height = "100vh"
		document.body.style.overflow = "hidden"
		console.log(tmpid);
	}

	removeModal(el, id) {
		document.body.removeChild(el.parentNode.parentNode);
		document.body.style.height = ""
		document.body.style.overflow = "auto"
		sessionStorage.setItem(id, 'hide')
	}
}



if (document.querySelector('#visioncare_modal')) {
	const amonModal = new AmonModal()
	if( !sessionStorage.getItem('visioncare_modal') )
	amonModal.showModalFromTemplate( visioncare_modal )
}
