const playVideo = (el) => {
	const videos = document.querySelectorAll('.hover__video')
	videos.forEach(v => v.pause())
	el.play()
}

const pauseVideo = (el) => {
	el.pause()
}


if (document.querySelector('.hover__video')) {
	
	const videos = document.querySelectorAll('.hover__video')
	videos.forEach(v => {
		v.addEventListener('mouseover', () => playVideo(v))
		v.addEventListener('touchstart', () => playVideo(v))
		v.addEventListener('mouseout', () => pauseVideo(v))
		v.addEventListener('touchend', () => pauseVideo(v))
	})

}

// Intersection Observer load video src from data-src when in viewport
const observer = new IntersectionObserver((entries) => {
	entries.forEach(entry => {
		if (entry.intersectionRatio > 0) {
			entry.target.src = entry.target.dataset.src
			// entry.target.play()
			observer.unobserve(entry.target)
		}
	})
}
)

const videos = document.querySelectorAll('.hover__video')
videos.forEach(v => observer.observe(v))
