// const mitarbeiterLink = document.querySelectorAll(".mitarbeiter .plus-small");
// const close = document.querySelectorAll(".close");

// mitarbeiterLink.forEach(m => {
//   m.addEventListener("click", e => {
//     let target = m.dataset.target;

//     document
//       .querySelector('[data-id="' + target + '"]')
//       .classList.add("active");
//     console.log(target);
//   });
// });

// close.forEach(x => {
//   x.addEventListener("click", e => {
//     x.parentNode.parentNode.classList.remove("active");
//   });
// });
document.addEventListener("touchstart",function(){},true)