const navLink = $(".nav-left li a");
const subNav = $(".nav-left li");
const navItem = $(".nav-item");

navLink.on("click", function() {
  if (
    $(this)
      .parent()
      .hasClass("active")
  ) {
    $(this)
      .parent()
      .removeClass("active");
  } else {
    subNav.removeClass("active");
    $(this)
      .parent()
      .addClass("active");
  }
});
