const range = document.getElementById("image-range");
const slidebar = document.querySelector(".slidebar");
const panoImage = document.getElementById("range--image");

// min Max Funktion
const mapValues = (value, inMin, inMax, outMin, outMax) =>
  ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

const movePanoImage = () => {
  console.log(range.value);
  let panoImageWidth = panoImage.offsetWidth - window.innerWidth;

  let offsetX = mapValues(range.value, 0, 100, 0, panoImageWidth);

  let widthX = mapValues(range.value, 0, 100, 1, 99);
  slidebar.style.width = widthX + "%";
  panoImage.style.transform = "translateX(" + offsetX * -1 + "px)";
};
if (range) {
  window.addEventListener("load", movePanoImage);
  window.addEventListener("resize", movePanoImage);
  range.addEventListener("input", movePanoImage);
  range.addEventListener("change", movePanoImage);
}
