const navItemSub = document.querySelectorAll(".nav-left li");
const nav = document.querySelector(".top--nav");

navItemSub.forEach(li => {
  li.addEventListener("click", e => {
    console.log(li.classList);
    // if (li.classList.contains("active")) {
    //   li.classList.remove("active");
    // } else {
    //   navItemSub.forEach(el => el.classList.remove("active"));
    //   li.classList.add("active");
    // }
  });
});

// nav.addEventListener("click", e => {
//   navItemSub.forEach(el => el.classList.remove("active"));
// });
