const openBrandDescription = index => { 
    const target = document.querySelector(`[data-target="${index}"]`)
    target.classList.add('active')
}
const closeBrandDescriptions = () => { 
    const target = document.querySelector('.brand--overlay-description-popup.active')
    // console.log(target.classList);
    target.classList.remove('active')
}

window.addEventListener('keyup', e => { 
    e.key === 'Escape' && closeBrandDescriptions()
})
window.addEventListener('click', e => { 
    e.target.classList.contains('popup--close') && closeBrandDescriptions()

})

// if (document.querySelector('.brand--overlay-description-popup .popup--close')) { 
//     const closeButtons = document.querySelector('.brand--overlay-description-popup .popup--close')
//     closeButtons.addEventListener('click', closeBrandDescriptions)
// }

