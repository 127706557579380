window.addEventListener('click', (e) => {
    if(e.target.id === 'uc-btn-accept-banner' || e.target.id === 'uc-btn-deny-banner' || e.target.dataset.event === 'save-settings') location.reload()
})

if (document.getElementById("searchRoute")) { 

    var searchRoute = document.getElementById("searchRoute");
    var inputSearch = searchRoute.start;
    var errorMessage = document.getElementById("msg");
    var routeLinkButton = document.getElementById("routeLink");
    
    var targetAddress = "49.975780, 9.147530";
    
    inputSearch.addEventListener("focus", function() {
    errorMessage.innerHTML = "";
    routeLinkButton.classList.remove("active");
    });
    inputSearch.addEventListener("input", function() {
    errorMessage.innerHTML = "";
    routeLinkButton.classList.remove("active");
    });
    
    window.onload = function() {
    searchRoute.reset();
    };
    function initMap() {
        var styledMapType = new google.maps.StyledMapType(
            [
            { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
            { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
            { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }]
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }]
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }]
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }]
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }]
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#dadada" }]
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }]
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }]
            },
            { featureType: "transit", stylers: [{ visibility: "off" }] },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }]
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }]
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c9c9c9" }]
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }]
            }
            ],
            { name: "Styled Map" }
    );
    
    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    
    var standort = { lat: 49.97578, lng: 9.14753 };
    
    var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: standort
    });
    directionsDisplay.setMap(map);
    
    //SETTING JSON MAP STYLES
    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");
    
    searchRoute.addEventListener("submit", function(e) {
        e.preventDefault();
        calculateAndDisplayRoute(directionsService, directionsDisplay);
    });
    
    // document.getElementById('searchZip').addEventListener('click', function() {
    // });
    
    var marker = new google.maps.Marker({
        position: standort,
        map: map,
        icon: "./assets/img/marker.svg"
    });
    }
    
    function calculateAndDisplayRoute(directionsService, directionsDisplay) {
    directionsService.route(
        {
        origin: searchRoute.start.value,
        destination: targetAddress,
        travelMode: "DRIVING"
        },
        function(response, status) {
        if (status === "OK") {
            var routeUrl =
            "https://maps.google.de/maps?saddr=" +
            searchRoute.start.value +
            "&daddr=" +
            targetAddress;
            directionsDisplay.setDirections(response);
            var route = response.routes[0];
            routeLinkButton.href = routeUrl;
            routeLinkButton.classList.add("active");
        } else {
            routeLinkButton.classList.remove("active");
            msg.innerHTML = "<b>Bitte gültige Adresse eingeben!</b>";
        }
        }
    );
    }

    const loadMap = () => { 
        const kontaktOffset = document.getElementById('kontakt-anfahrt').offsetTop
        const windowOffsetY = window.pageYOffset
        const windowHeight = window.innerHeight
        const map = document.getElementById('map')
        // console.log('SCROLLED: ', windowOffsetY, 'HEIGHT: ', windowHeight, 'KONTAKT: ', kontaktOffset);
        if (kontaktOffset - windowOffsetY < windowHeight && map.childElementCount === 0) {
            initMap()
         }
    }

    // window.addEventListener('scroll', debounce(loadMap, 0, 20))
    window.addEventListener('resize', () => {
        if(window.google) loadMap()
    })
    window.addEventListener('load', () => {
        if (window.google) { setTimeout(initMap(), 500) }
    })

    let mapIsShowing = false;

    if (window.dataLayer) { 
        dataLayer.map(data => {
            if (data['Google Maps'] === true) mapIsShowing = true
        })
    }

    const showMapMessage = (bool, id) => {
        if (!bool) { 
            document.getElementById(id).innerHTML = `<p style="margin-bottom: 0">Bitte stimmen Sie der Verwendung von Google Maps zu, um den Routenplaner zu verwenden</p><br><a href="#${id}" class="as-btn full--btn" onclick="usercentrics.updateConsents([{templateId: 'S1pcEj_jZX', status: true}]); location.reload()">Zustimmen</a>`
        }
    }
    
    showMapMessage(mapIsShowing, 'searchRoute')



}  
