// Fade In und Fade Out Funktion

function fadeOut(el) {
  el.style.opacity = 1;
  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

function fadeIn(el, display) {
  el.style.opacity = 0;
  el.style.display = display || "";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

//============================================
// Filter
//============================================

if (document.querySelectorAll(".pagination--link")) {
  const links = document.querySelectorAll(".pagination--link");
  const items = document.querySelectorAll(".image--grid-row");
  const glossarItem = document.querySelectorAll(".glossar--entry");

  links.forEach(a => {
    a.addEventListener("click", e => {
      e.preventDefault();
      links.forEach(l => {
        l.classList.remove("active");
      });

      let target = e.target.dataset.target;
      filterItems(target);
      a.classList.add("active");
    });
  });

  const scrollToArea = letter => {
    document
      .querySelector('[data-letter="' + letter + '"]')
      .scrollIntoView({ behavior: "smooth" });
  };

  const filterItems = item => {
    items.forEach(el => {
      if (item === "all") {
        fadeIn(el);
        //el.style.display = "";
      } else {
        // el.dataset.letter === item
        //   ? (el.style.display = "")
        //   : (el.style.display = "none");
        el.dataset.letter === item ? fadeIn(el, "") : fadeOut(el);
      }
    });
    glossarItem.forEach(el => {
      if (item === "all") {
        fadeIn(el);
        //el.style.display = "";
      } else {
        // el.dataset.letter === item
        //   ? (el.style.display = "")
        //   : (el.style.display = "none");
        el.dataset.letter === item ? fadeIn(el, "") : fadeOut(el);
      }
    });
  };
}
